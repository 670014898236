.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:FFFDFA;
    flex: 1; /* Fülle verfügbaren vertikalen Platz aus */
    min-height: 100vh; /* Mindesthöhe auf die volle Bildschirmhöhe setzen */
    
    margin-left: 12%;
    margin-right: 12%;
    text-align: center;
    font-size: 120%;
    line-height: 2.2rem;
    font-family: Canva Sans,Noto Sans Variable,Noto Sans,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif;
    font-variation-settings: "opsz" 0;
    font-weight: 500;
    overflow-wrap: break-word;
    
  }
  .mainnews{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color:FFFDFA;
    flex: 1; /* Fülle verfügbaren vertikalen Platz aus */
    min-width: 100vh;
    
  }
  
  @media (max-width:800px){
  
    .main{
      
     
      /*font-size: 100%;*/
      margin: 5%;
      
      .p{
        overflow: scroll;
        text-align: start;
      }
      
    }
    
  }
  
 

.newdiv{
  margin-top: auto;
 /* margin-left: 10px;*/
}

.b_wlcmTile p {
  font-size: 16px;
  margin-top: 8px;
  line-height: 22px;
  color: var(--cib-color-foreground-neutral-primary);
  overflow-wrap: anywhere;
}
a:hover{
font-size: 1.2em;
color: #61dafb;
}


.image{
  margin-left: 0%;
  margin-right: 0%;
  width: 100%;
  height: 30%;
  
 
}

.defilement-texte {
  white-space: nowrap; /* Empêche le texte de passer à la ligne */
  overflow: hidden; /* Cache le texte qui dépasse */
  position: relative; /* Position relative pour le texte animé */
  animation: defilement 20s linear infinite; /* Animation appelée 'defilement' */
}

.defilement-texte-container {
  display: flex;
  overflow: hidden;
  margin-top: 50px;
  margin-bottom: '0px';
  animation: defilement 40s linear infinite;
  position: absolute;
  
 /* width: 100%; /* Breite auf 100% des Containers setzen */
  background-color: #f0f0f0; /* Hintergrundfarbe für das Element */
  padding: 20px; /* Innenabstand für den Inhalt des Elements */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Schatten für den unteren Rand */
  
}

.defilement-item {
  flex-shrink: 0;
  margin-right: 20px; /* Espace entre chaque élément, ajustez selon votre préférence */
}

.defilement-item img {
  width: 100px; /* Ajustez la largeur des images selon vos besoins */
  height: auto; /* Permet à la hauteur de s'ajuster automatiquement */
}

@keyframes defilement {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

input {
  flex: 0.8;
  border: 2px solid;
  margin: 10px;
  font-size: 16px;
  padding: 8px;
  outline: none;
}



input[type="text"] {
  display: inline-block;
 
  width: 200px; /* Breite des Eingabefelds anpassen */
}

textarea{
  margin: 10px;
 width: 45%;
  height: 100px;
}













